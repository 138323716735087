<template>
  <div class="featured-card" :style="`--nr-animation-transform-x:${trasnformYFx}`">
    <div class="featured-card-content" transition :style="`--nr-animation-transform-y:0`">
      <div class="news-card-cover">
        <NewsCardPoster class="card" :news="news" />
      </div>
      <div class="news-card-data">
        <div class="news-card-data-desc">
          <div class="news-card-title">
            <span class="text">{{ news.titleNews || "Sin titulo" }}</span>
          </div>
        </div>
        <div class="news-card-social">
          <div class="news-card-date">
            <div>{{ $global.dateLocaleFormat(news.date) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsMixing from "./NewsMixing";
export default {
  mixins: [NewsMixing],
  props: ["news", "index", "toggleKey", "validFileIcon", "cutName", "isEdit", "handleComments"],
  components: {
    MarkOffer: () => import("@/components/widgets/MarkOffer"),
    Toggle: () => import("@/components/input/ToggleInput.vue"),
    LikesInfo: () => import("@/components/likes/LikeInfo.vue"),
    NewsCardPoster: () => import("./NewsCardPoster.vue"),
    NewsCardSocial: () => import("./NewsCardSocial.vue"),
    NewsCardOptions: () => import("./NewsCardOptions.vue"),
  },
  computed: {
    user: function() {
      return this.$profile;
    },
    trasnformYFx: function() {
      let y = `100%`;
      return y;
    },
  },
  methods: {
    getShortDesc: function(text, limit = 100) {
      return this.$global.getShort(text, limit);
    },
    getPoster: function(news) {
      const emptyPoster = `https://new-web-site-bucket.s3.amazonaws.com/news/notice_empty.jpg`;
      return news.thumbnail || news.imageNews || emptyPoster;
    },
  },
};
</script>

<style lang="scss">
.featured-card {
  display: inline-flex;
  height: 280px;
  width: 100%;
  &-content {
    .card {
      width: 100%;
      height: 300px;
      .news-card-poster-play {
        transform: translate(175%, -50%);
      }
    }
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: $mpadding/1.5;
    overflow: hidden;
    position: relative;
    flex: auto;
    box-shadow: $dshadow;
    @include Animation(scroll-animation-card, 1.5s, 3.5s);
    /* &:hover {
      @include BigShadow();
    } */
  }
  .news-card-data {
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #fff;
    background: linear-gradient(1deg, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 80%);
    padding: $mpadding * 5 0 0 0;
    pointer-events: none;
  }
  @media (max-width: 425px) {
    &-content {
      .card {
        width: 100%;
        height: 300px;
        .news-card-poster-play {
          transform: translate(130%, -50%);
        }
      }
    }
  }
}
</style>
